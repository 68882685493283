.ProfileContainer {
  width: 1000px;
  margin: 0 auto;
  padding: 40px 0; }
  @media (max-width: 480px) {
    .ProfileContainer {
      width: 100%;
      position: relative; } }

.profile {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 0 0; }
  .profile .info {
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    padding: 0 40px;
    position: relative;
    height: 260px;
    overflow: hidden;
    z-index: 1;
    background: #06a763;
    color: #fff;
    margin: -33px 0px 0 0;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
    .profile .info .titlePic {
      position: absolute;
      right: 0;
      z-index: 1;
      float: right; }
      .profile .info .titlePic img {
        width: 400px;
        height: auto;
        display: block;
        max-width: 100%;
        vertical-align: middle;
        border: 0; }
      .profile .info .titlePic .slant {
        top: 0;
        z-index: 1;
        position: absolute;
        border-style: solid;
        border-width: 400px 0 0 100px;
        border-color: rgba(223, 50, 50, 0) transparent transparent #06a763; }
      .profile .info .titlePic .add-btn {
        display: inline-block;
        text-decoration: none;
        color: #fff;
        letter-spacing: 0.5px;
        position: absolute;
        background-color: #1976d2;
        top: 20%;
        right: 86%;
        overflow: hidden;
        width: 55.5px;
        height: 55.5px;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;
        text-transform: uppercase;
        vertical-align: middle;
        text-align: center;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        box-sizing: border-box;
        z-index: 1; }
        .profile .info .titlePic .add-btn i {
          font-size: 35px;
          color: #fff;
          line-height: 55.5px; }
    @media (max-width: 480px) {
      .profile .info .details {
        margin-top: 350px;
        position: relative; } }
    .profile .info .details h4 {
      display: block;
      width: 100%;
      font-weight: 900;
      color: #fff;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
      font-size: 2.18rem;
      line-height: 110%;
      margin: 1.14rem 0 0.912rem 0; }
    .profile .info .details h6 {
      display: block;
      width: 100%;
      font-weight: 300;
      margin-top: -10px;
      margin-bottom: 30px;
      font-size: 1.2em;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29); }
    .profile .info .details > p {
      display: flex;
      align-items: center;
      margin: 5px; }
      .profile .info .details > p > i {
        margin-right: 10px; }
    .profile .info .details .social {
      display: flex;
      padding: 5px; }
      .profile .info .details .social > a {
        color: #fff;
        margin-right: 12px;
        margin-bottom: 4px;
        line-height: 37px;
        display: inline-block;
        position: relative;
        overflow: hidden;
        z-index: 1;
        width: 37px;
        height: 37px;
        padding: 0;
        border-radius: 50%;
        cursor: pointer;
        box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
        text-align: center; }
        .profile .info .details .social > a i {
          font-size: 15px;
          vertical-align: middle;
          padding-bottom: 15px;
          color: #fff;
          line-height: 37px;
          display: inline-block;
          text-align: center; }
        .profile .info .details .social > a:hover {
          text-decoration: none;
          outline: 0;
          box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }
        .profile .info .details .social > a.twitter {
          background-color: #2196f3; }
          .profile .info .details .social > a.twitter:hover {
            background-color: #2988e6; }
        .profile .info .details .social > a.linkedin {
          background-color: #1565c0; }
          .profile .info .details .social > a.linkedin:hover {
            background-color: #2988e6; }
        .profile .info .details .social > a.github {
          background-color: #333; }
          .profile .info .details .social > a.github:hover {
            background-color: #222; }
  .profile .description {
    margin-top: 30px;
    border-radius: 0 0 2px 2px; }
    .profile .description p {
      padding: 40px;
      margin: 0;
      color: inherit;
      text-align: center;
      font-weight: 400; }
    .profile .description .actions {
      border-top: 1px solid rgba(160, 160, 160, 0.2);
      padding: 20px; }
      .profile .description .actions .about-btn {
        height: auto;
        margin-right: auto;
        margin-left: auto;
        animation-delay: 1s;
        text-align: center; }
        .profile .description .actions .about-btn .btn {
          display: inline-block;
          padding: 6px 12px;
          font-size: 14px;
          font-weight: 400;
          line-height: 1.42857143;
          text-align: center;
          white-space: nowrap;
          vertical-align: middle;
          -ms-touch-action: manipulation;
          touch-action: manipulation;
          cursor: pointer;
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          background-image: none;
          border: 1px solid transparent;
          margin: 7px 7px;
          background: #06a763;
          color: #fff;
          width: 180px;
          border-radius: 2px;
          padding-top: 8px;
          position: relative;
          overflow: hidden;
          z-index: 1;
          will-change: opacity, transform;
          text-decoration: none;
          letter-spacing: 0.5px;
          box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
          .profile .description .actions .about-btn .btn:hover {
            background: #05985a;
            text-decoration: none;
            box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15); }

.profile-container {
  margin: 10px auto;
  padding: 10px;
  width: 80%;
  height: 100%;
  display: flex; }
  @media (max-width: 480px) {
    .profile-container {
      width: 100%;
      margin: auto; } }
