@import url(~font-awesome/css/font-awesome.css);
body {
  margin: 0;
  padding: 0;
  font-family: 'Roboto', sans-serif;
  display: flex;
  background-color: #f5f5f5; }

#root {
  display: flex;
  flex-direction: column;
  flex: 1; }

@font-face {
  font-family: 'Material Icons';
  font-style: normal;
  font-weight: 400;
  src: local("Material Icons"), local("MaterialIcons-Regular"), url("~material-design-icons/iconfont/MaterialIcons-Regular.woff2") format("woff2"), url("~material-design-icons/iconfont/MaterialIcons-Regular.woff") format("woff"), url("~material-design-icons/iconfont/MaterialIcons-Regular.ttf") format("truetype"); }

.material-icons {
  font-family: 'Material Icons', sans-serif;
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga'; }
