@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"), local("Roboto-Light"), url(./Fonts/RobotoLight.woff2) format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"), local("Roboto-Regular"), url(./Fonts/RobotoRegular.woff2) format("woff2"); }

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"), local("Roboto-Medium"), url(./Fonts/RobotoMedium.woff2) format("woff2"); }
