.experience {
  padding: 5px;
  color: #616161; }
  .experience a {
    color: inherit;
    text-decoration: none; }
  .experience h4 {
    font-weight: normal;
    font-size: 1.4em;
    margin: 0;
    line-height: 1.1;
    text-decoration: none; }
  .experience h6 {
    color: #777;
    font-size: 0.75em;
    margin: 10px 0;
    font-weight: 400; }
  .experience p.note:before {
    content: '* ';
    font-weight: bold; }

.ExperienceTimeline .timelineBlock > .timelineContent {
  background-color: white;
  padding-top: 5px;
  padding-bottom: 5px; }

.ExperienceList .header {
  display: flex; }
  .ExperienceList .header h4 {
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #616161;
    font-weight: 600;
    font-size: 2.18rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0; }
    .ExperienceList .header h4 span i {
      font-weight: 600;
      font-size: 2.18rem;
      line-height: 110%;
      margin: 1.14rem 0 0.912rem 0;
      color: #06a763;
      margin-right: 10px; }
