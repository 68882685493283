.Skills {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  background-color: #06a763;
  color: #fff;
  text-align: center;
  padding: 30px; }
  .Skills h6 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29);
    font-size: 1.4em;
    font-weight: 500;
    margin-top: 10px;
    margin-bottom: 10px;
    line-height: 1.1; }
  .Skills > div {
    display: flex;
    flex-direction: column;
    max-width: 300px;
    flex: 0.5; }
  .Skills .skill {
    display: flex;
    flex-direction: row;
    margin-bottom: 2px; }
    .Skills .skill p {
      margin: 0;
      text-align: left;
      flex: auto;
      font-size: 1em;
      line-height: 15px;
      padding: 3px 0; }
      .Skills .skill p:last-child {
        font-size: 0.8em;
        text-align: right; }
  .Skills .skillInfo {
    display: flex;
    flex-direction: column;
    margin-bottom: 5px; }
    .Skills .skillInfo .bar {
      height: 4px;
      background: #18975c; }
      .Skills .skillInfo .bar div {
        background-color: #ffffff;
        height: 4px; }

.SkillsWrapper {
  margin-top: 30px; }
  .SkillsWrapper > div.header h4 {
    display: flex;
    flex: 1;
    text-align: center;
    align-items: center;
    justify-content: center;
    color: #616161;
    font-weight: 600;
    font-size: 2.18rem;
    line-height: 110%;
    margin: 1.14rem 0 0.912rem 0; }
    .SkillsWrapper > div.header h4 span i {
      font-weight: 600;
      font-size: 2.18rem;
      line-height: 110%;
      margin: 1.14rem 0 0.912rem 0;
      color: #06a763;
      margin-right: 10px; }
