.Timeline {
  position: relative;
  margin-top: 10px; }
  .Timeline::after {
    content: '';
    position: absolute;
    width: 6px;
    background-color: #06a763;
    top: 0;
    bottom: 0;
    left: 50%;
    margin-left: -3px; }
  .Timeline .timelineBlock {
    position: relative;
    background-color: inherit;
    width: 50%;
    box-sizing: border-box; }
    .Timeline .timelineBlock:nth-child(odd) {
      padding: 10px 40px 10px 20px;
      left: 0; }
      .Timeline .timelineBlock:nth-child(odd)::before {
        content: ' ';
        height: 5px;
        position: absolute;
        top: 35px;
        width: 20px;
        z-index: 1;
        right: 25px;
        background-color: #06a763; }
    .Timeline .timelineBlock:nth-child(even) {
      padding: 10px 20px 10px 40px;
      left: 50%; }
      .Timeline .timelineBlock:nth-child(even)::before {
        content: ' ';
        height: 5px;
        position: absolute;
        top: 35px;
        width: 20px;
        z-index: 1;
        left: 25px;
        background-color: #06a763; }
      .Timeline .timelineBlock:nth-child(even) .timelineDot {
        left: -25px; }
  .Timeline .timelineContent {
    background-color: #06a763;
    position: relative;
    border-left: 5px solid #1976d2;
    border-radius: 0 0 2px 2px;
    visibility: visible;
    opacity: 1;
    z-index: 2;
    box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12); }
    .Timeline .timelineContent:nth-child(odd) {
      padding: 20px 30px; }
    .Timeline .timelineContent:nth-child(even) {
      padding: 30px 20px; }
  .Timeline .timelineDot {
    position: absolute;
    width: 50px;
    height: 50px;
    right: -25px;
    background-color: #06a763;
    top: 10px;
    border-radius: 50%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #ffffff; }
    .Timeline .timelineDot h6 {
      text-align: center;
      padding: 0;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.29); }
